import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

export default function Heading({ children, level, align, ...props }) {
  const cx = classnames('presentation--heading', {
    [`text-${align}`]: align
  });

  return (
    React.createElement(`h${level}`, { className: cx, ...props }, children)
  );
}

Heading.defaultProps = {
  level: 1
};

Heading.propTypes = {
  level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  align: PropTypes.oneOf(['center', 'left', 'right'])
};
