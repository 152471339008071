import React from 'react';
import { getFolders } from '../services/Pagedata';
import DataList from './DataList';

const getList = pages => pages.map(page => ({ text: page, link: `_list/${page}` }));

export default function FoldersList() {
  return (
    <DataList
      title="Folders"
      getData={getFolders}
      getList={getList}
    />
  );
}
