import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default function Column({ children, size }) {
  const cx = classNames('presentation--column flex-sm-12 spacing-md', `flex-${size}`);

  return (
    <div className={cx}>
      {children}
    </div>
  );
}

Column.defaultProps = {
  size: 6
};

Column.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
