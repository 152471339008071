import React from 'react';
import PropTypes from 'prop-types';

export default function Image({ src, caption, alt }) {
  let figcaption = null;

  if (caption) {
    figcaption = (<figcaption>{caption}</figcaption>);
  }

  return (
    <figure className="presentation--image">
      <img src={src} alt={alt} />
      {figcaption}
    </figure>
  );
}

Image.defaultProps = {
  alt: ''
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  caption: PropTypes.string,
  alt: PropTypes.string
};
