import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../components/LoadingSpinner';

export default function DataList({ title, getData, getList }) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData().then(response => {
      setData(response);
      setIsLoading(false);
    });
  }, [getData]);
  let content;

  if (isLoading) {
    content = <LoadingSpinner />;
  } else {
    content = (
      <ul className="font-large">
        {getList(data).map(page => (
          <li key={page.link}>
            <Link to={page.link}>
              <h3 className="font-light">{page.text}</h3>
            </Link>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="projects--list">
      <div className="container text-center">
        <h1 className="spacing-lg">{title}</h1>
        {content}
      </div>
    </div>
  );
}
