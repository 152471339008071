import React, { useState, useEffect } from 'react';
import { getPage } from './services/Pagedata';

const CommonDataContext = React.createContext({});

export const CommonDataProvider = ({ children }) => {
  const [data, setData] = useState({});
  const fetchData = async () => {
    try {
      const response = await getPage('includes/common');
      const content = response.data.content.data;

      setData(content);
    } catch (error) {
      // error
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <CommonDataContext.Provider value={data}>
      {children}
    </CommonDataContext.Provider>
  );
};

export default CommonDataContext;
