import { getPage } from '../../services/Pagedata';

const INCLUDE_REG = /<Include.*?slug="(.*?)"[^>]+>/g;
const SLUG_REG = /slug\s*=\s*"([^"]+)"/;

export default async function includeTransformer(obj) {
  let { contentRaw } = obj;
  const matches = contentRaw.match(INCLUDE_REG);
  const includeTags = [...new Set(matches)];
  const promises = [];

  includeTags.forEach(include => {
    const [, slug] = include.match(SLUG_REG);
    promises.push(getPage(slug));
  });

  const includes = await Promise.all(promises);

  includes.forEach(({ data }, index) => {
    contentRaw = contentRaw.split(includeTags[index]).join(data.content.data.content);
  });

  obj.contentRaw = contentRaw;

  return obj;
}
