import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_PAGEDATA_ENDPOINT,
  headers: {
    'Content-Type': 'application/json',
    'X-Api-Token': process.env.REACT_APP_PAGEDATA_KEY
  }
});

const params = {
  status: process.env.REACT_APP_PAGEDATA_STATUS,
};

/**
 * Fetch pagedata document
 *
 * @export
 * @param {string} [slug=''] Pagedata document slug
 * @param {object} [options={}] Request options
 * @returns {Promise}
 */
function getPage(slug = '', options = {}) {
  const obj = Object.assign({}, params, options);
  return axiosInstance.get(`/pages/${slug}`, { params: obj });
}

/**
 * Fetch pagedata folders
 *
 * @export
 * @returns {Promise}
 */
function getFolders() {
  return axiosInstance.get('/folders')
    .then(response => response.data.filter(page => {
      const notHidden = page.indexOf('_') !== 0;
      const notIncludes = page.indexOf('includes') < 0;

      return notHidden && notIncludes;
    }));
}

export { getPage, getFolders };
