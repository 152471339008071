import React from 'react';

export default function Icon({ name, width = 16, height = 16 }) {
  const viewBox = `0 0 ${width} ${height}`;

  return (
    <svg className="svg-icon flex-no-shrink" role="presentation" aria-hidden="true" width={width} height={height} viewBox={viewBox}>
      <use xlinkHref={`#${name}`} />
    </svg>
  );
}
