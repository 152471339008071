import { off, on } from 'domassist';
import { withRouter } from 'react-router';
import classNames from 'classnames';
import MDX from '@mdx-js/runtime';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Column from './Components/Column';
import Heading from './Components/Heading';
import Icon from '../../icons/Icon';
import Image from './Components/Image';
import Include from './Components/Include';
import PresentationHeader from './PresentationHeader';
import Slide from './Components/Slide';
import SwiperWrapper from '../../components/SwiperWrapper';
import Text from './Components/Text';
import TitleSlide from './Components/TitleSlide';

const AVAILABLE_COLORS = [
  'white',
  'blue',
  'red',
  'green'
];

class PresentationDetail extends Component {
  constructor(props) {
    super(props);

    let index = parseInt(props.match.params.index, 10);
    let mode = 'presentation';

    // NaN
    if (Number.isNaN(index)) {
      index = 1;

      if (props.match.params.index === 'read') {
        mode = 'read';
      }
    }

    const initialSlide = index - 1;

    this.state = {
      mode,
      bgColor: 'blue',
      redirect: false,
      headings: []
    };

    this.components = {
      Column,
      Heading,
      Image,
      Include,
      Slide,
      Text,
      TitleSlide
    };

    this.options = {
      autoHeight: true,
      slidesPerView: 1,
      centeredSlides: true,
      simulateTouch: false,
      initialSlide,
      renderPagination: () => null,
      updateSwiper: this.updateSwiper.bind(this),
      pagination: {
        el: '.presentation--pagination--bullets',
        type: 'bullets',
        clickable: true,
        renderBullet: (bulletIndex, className) => {
          const regex = /"/gi;
          if (props.headings.length >= 1) {
            return `<span class="${className}" data-heading="${props.headings[bulletIndex].replace(regex, '&quot;')}"></span>`;
          }
        }
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    };
    this.swiper = null;
    this.handleKeyUp = this.handleKeyUp.bind(this);

    on(document, 'keyup', this.handleKeyUp);
  }

  handleKeyUp(event) {
    const key = event.key || event.keyCode || event.code;

    if (!this.swiper) {
      return;
    }

    if (key === 39 || key === 'ArrowRight' || key === 32 || key === ' ' || key === 'Space') { // space bar and right arrow
      event.preventDefault();
      this.swiper.slideNext();
    } else if (key === 37 || key === 'ArrowLeft') { // left arrow
      event.preventDefault();
      this.swiper.slidePrev();
    }
  }

  componentWillUnmount() {
    off(document, 'keyup');
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.mode !== this.state.mode) {
      this.updateUrl();
    }
  }

  getCurrentIndex() {
    let index;

    if (this.swiper) {
      index = this.swiper.activeIndex;
    } else {
      index = this.options.initialSlide;
    }

    return index;
  }

  updateUrl() {
    let index;

    if (this.state.mode === 'presentation') {
      index = this.getCurrentIndex() + 1;
    } else {
      index = 'read';
    }
    const url = `/${this.props.url}/${index}`;

    if (url !== this.props.history.location.pathname) {
      this.props.history.push(url);
    }
  }

  onSlideChange() {
    const currentIndex = this.getCurrentIndex();
    const bgColor = this.swiper.slides[currentIndex].dataset.color;
    this.updateUrl();
    this.setState({ bgColor });

    this.swiper.updateAutoHeight(0);
  }

  componentDidMount() {
    this.updateUrl();
  }

  updateSwiper(swiper) {
    this.swiper = swiper;

    if (swiper) {
      // In case color is different for first slide
      this.onSlideChange();

      this.swiper.on('slideChange', () => {
        this.swiper.update();
        this.onSlideChange();
      });
    }

    this.forceUpdate();
  }

  render() {
    const {
      mode,
      bgColor
    } = this.state;
    const swiper = this.swiper;
    const pageNumber = this.getCurrentIndex() + 1;
    const slidesAmount = this.swiper ? swiper.slides.length : 1;
    const isPresentation = mode === 'presentation';
    const isReadMode = !isPresentation;

    const cx = classNames('presentation display-flex-column', {
      [`color-${bgColor}`]: bgColor,
      'is-read-mode': isReadMode,
      'is-presentation-mode': isPresentation
    });

    const colorLayers = AVAILABLE_COLORS.map(color => <div className={`presentation__layer presentation__layer--${color}`} key={color} />);

    let pagination = null;
    let content;

    if (isPresentation) {
      pagination = (
        <div className="presentation--pagination margin-yaxis-md">
          <div className="container">
            <div className="display-inline-flex flex-left-center">
              <div className="presentation--pagination--fraction margin-right-sm">{pageNumber} <span className="font-small">of</span> {slidesAmount}</div>
              <div className="presentation--pagination--bullets" />
            </div>
          </div>
        </div>
      );

      content = (<SwiperWrapper {...this.options}><MDX components={this.components}>{this.props.slides}</MDX></SwiperWrapper>);
    } else {
      content = <MDX components={this.components}>{this.props.slides}</MDX>;
    }

    return (
      <div className={cx}>
        {colorLayers}
        <PresentationHeader>
          <div className="presentation__buttons">
            <span>{mode === 'read' ? 'View Presentation' : 'View Document'}</span>
            <button className="presentation__button presentation__button--read" onClick={() => this.setState({ mode: 'read' })} title="Read Mode">
              <Icon name="document" />
            </button>

            <button className="presentation__button presentation__button--presentation" onClick={() => this.setState({ mode: 'presentation' })} title="Presentation Mode">
              <Icon name="slide" />
            </button>
          </div>
        </PresentationHeader>
        {content}

        {pagination}
      </div>
    );
  }
}

PresentationDetail.propTypes = {
  slides: PropTypes.string.isRequired
};

export default withRouter(PresentationDetail);
