import React, { useEffect, useRef } from 'react';
import Swiper from 'react-id-swiper';

export default function SwiperWrapper({ children, updateSwiper, ...options }) {
  const swiper = useRef(null);

  useEffect(() => () => {
    if (swiper.current) {
      swiper.current.destroy();
      updateSwiper(null);
    }
  }, [updateSwiper]);

  useEffect(() => updateSwiper(swiper.current), [swiper, updateSwiper]);

  return <Swiper {...options} getSwiper={s => {
    swiper.current = s;
  }}>{children}</Swiper>;
}
