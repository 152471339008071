import React from 'react';
import Slide from './Slide';
import Column from './Column';
import Text from './Text';

function toArray(stringOrArray) {
  const aux = [];
  if (!Array.isArray(stringOrArray)) {
    aux.push(stringOrArray);
  } else {
    Array.prototype.push.apply(aux, stringOrArray);
  }
  return aux;
}

function getPersonsMarkup(array) {
  return array.map(person => (
    <>
      {person}
      <br />
    </>
  ));
}

export default function TitleSlide({
  client,
  project,
  preparedFor,
  preparedBy
}) {
  const prepFor = toArray(preparedFor);
  const prepBy = toArray(preparedBy);
  const prepForMarkup = getPersonsMarkup(prepFor);
  const prepByMarkup = getPersonsMarkup(prepBy);
  return (
    <Slide row>
      <Column>
        <h1>{client}</h1>
        <h2>{project}</h2>
      </Column>
      <Column>
        --
        <Text uppercase>
          <b>Prepared For</b>
        </Text>
        {prepForMarkup}
        <br />
        --
        <br />
        <Text uppercase>
          <b>Prepared By</b>
        </Text>
        {prepByMarkup}
      </Column>
    </Slide>
  );
}
