const SLIDE_REG = /---(\[(.*?)\])?\n?/g;

export default function slideTransformer(obj) {
  const slideShortcutOccurrence = obj.contentRaw.search(SLIDE_REG);
  const hasShortCuts = slideShortcutOccurrence > -1;

  if (!hasShortCuts) {
    return;
  }

  const hasInitialSlide = slideShortcutOccurrence === 0;
  let { contentRaw } = obj;

  // Most complicated scenario
  // ---[color=green,row]
  contentRaw = contentRaw.replace(SLIDE_REG, (...args) => {
    const [, , slideParams] = args;
    const replacement = '<Slide';
    let props = '';

    // Can be undefined if separator is just "---"
    if (slideParams) {
      props = slideParams.replace(/"/g, '')
        .split(',')
        .reduce((previousValue, currentValue) => {
          // Accounting for key=value
          if (currentValue.indexOf('=') > -1) {
            return `${previousValue} ${currentValue.replace('=', '="')}"`;
          }

          // Just return prop
          return `${previousValue} ${currentValue}`;
        }, '')
        .trim();

      props = ` ${props}`;
    }

    return `</Slide>\n\n${replacement}${props}>\n`;
  });

  // Initial slide can have extra parameters with the above notation, otherwise prepend <Slide>
  if (!hasInitialSlide) {
    contentRaw = `<Slide>\n\n${contentRaw}`;
  }

  contentRaw = `${contentRaw}\n</Slide>`;

  obj.contentRaw = contentRaw;

  return Promise.resolve(obj);
}
