import React, { useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { getFolders } from '../services/Pagedata';

const endRegex = /\/(\d{1,2}|read)$/;

function RouteFixer({ location }) {
  const [newUrl, setNewUrl] = useState(null);

  useEffect(() => {
    getFolders().then(folders => {
      const cleanPath = location.pathname.replace(endRegex, '');
      const aux = cleanPath.split('/');
      const folder = folders.filter(f => cleanPath.indexOf(f) > -1)[0];
      let url = '/404';

      // New urls have "/folder/slug/" structure which would lead to 3 fragments
      if (aux.length < 3) {
        if (folder) {
          url = location.pathname.replace(cleanPath, cleanPath.replace(`${folder}-`, `${folder}/`));
        } else {
          url = location.pathname.replace(cleanPath, aux.join('/').replace('-', '/'));
        }

        if (!endRegex.exec(url)) {
          url += '/1';
        }
      } else {
        if (!endRegex.exec(location.pathname)) {
          url = `${location.pathname}/1`;
        }
      }

      if (url) {
        setNewUrl(url);
      }
    });
  }, [location.pathname]);

  if (newUrl) {
    return <Redirect to={newUrl} />;
  }

  return null;
}

export default withRouter(RouteFixer);
