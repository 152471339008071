import React from 'react';
import PropTypes from 'prop-types';

export default function Slide({ children, row, color }) {
  const rowTemplate = content => <div className="flex-row flex-wrap flex-space-between-center">{content}</div>;

  return (
    <div className="swiper-slide presentation--slide" data-color={color}>
      <div className="container">
        {row ? rowTemplate(children) : children}
      </div>
    </div>
  );
}

Slide.defaultProps = {
  color: 'blue'
};

Slide.propTypes = {
  row: PropTypes.bool,
  color: PropTypes.oneOf(['blue', 'red', 'green'])
};
