import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { getPage } from '../../../services/Pagedata';
import MDX from '@mdx-js/runtime';

export default function Include({ slug }) {
  const [content, setContent] = useState('Loading...');

  useEffect(() => {
    let isSubscribed = true;

    getPage(slug).then(({ data }) => {
      if (isSubscribed) {
        setContent(data.contentRaw);
      }
    }).catch(() => {
      if (isSubscribed) {
        setContent('');
      }
    });

    return () => (isSubscribed = false);
  }, [slug]);

  return <MDX>{content}</MDX>;
}

Include.propTypes = {
  slug: PropTypes.string.isRequired
};
