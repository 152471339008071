export default function headingsParser({ mdx }) {
  const slides = mdx.split('<Slide');
  const headings = [];

  slides.shift();

  let heading = null;

  slides.forEach(item => {
    heading = '';
    if (item.indexOf('<h1>') !== -1) {
      heading = item.slice(item.indexOf('<h1>') + 6, item.indexOf('</h1>') - 2);
    } else if (item.indexOf('<h2>') !== -1) {
      heading = item.slice(item.indexOf('<h2>') + 6, item.indexOf('</h2>') - 2);
    } else if (item.indexOf('<Heading') !== -1) {
      heading = item.slice(item.indexOf('>', item.indexOf('<Heading')) + 1, item.indexOf('</Heading>'));
    }
    headings.push(heading);
  });

  return headings;
}
