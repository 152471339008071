import { withRouter } from 'react-router';
import React, { Component } from 'react';
import queryString from 'query-string';
import { getPage } from '../../services/Pagedata';
import Loading from '../../components/Loading';
import PresentationDetail from './PresentationDetail';
import parse from '../../content/parser';
import transform from '../../content/transformations';

class Presentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      url: '',
      slides: '',
      headings: []
    };
  }

  componentDidMount() {
    this.fetchPresentation();
  }

  fetchPresentation() {
    const { slug, folder } = this.props.match.params;
    const url = [folder, slug].join('/');
    const query = queryString.parse(window.location.search, { ignoreQueryPrefix: true });

    this.setState({
      loading: true
    }, async () => {
      try {
        const response = await getPage(url, query);
        const data = await transform(response.data, this.props.match.params);
        const { contentRaw, title, headings } = parse(data);

        document.title = title;

        this.setState({
          slides: contentRaw,
          headings,
          url,
          loading: false
        });
      } catch (error) {
        this.props.history.push('/404');
      }
    }
    );
  }

  render() {
    const {
      loading,
      slides,
      url,
      headings
    } = this.state;

    let template;

    if (loading) {
      template = <Loading />;
    } else {
      template = <PresentationDetail slides={slides} headings={headings} url={url} />;
    }

    return template;
  }
}

export default withRouter(Presentation);
