import slideTransformer from './slide';
import includeTransformer from './include';

const transformers = [
  includeTransformer,
  slideTransformer,
];

export default async function transform(obj, params) {
  const transformed = {
    contentRaw: obj.content.data.content,
    name: obj.name,
    folder: params.folder,
    slug: params.folder
  };

  let result;

  await transformers.reduce(async (data, transformer) => {
    const content = await data;

    try {
      const transformedContent = await transformer(transformed || content);

      if (transformedContent) {
        result = transformedContent;
      }

      return transformedContent;
    } catch (error) {
      return data;
    }
  }, Promise.resolve(transformed));

  return result;
}
