import titleParser from './title';
import mdxParser from './mdx';
import headingsParser from './headings';
import TOCParser from './tableofcontents';

const parsers = [
  {
    key: 'title',
    fn: titleParser
  },
  {
    key: 'mdx',
    fn: mdxParser
  },
  {
    key: 'headings',
    fn: headingsParser
  },
  {
    key: 'contentRaw',
    fn: TOCParser
  }
];

export default function parse(obj) {
  const data = Object.assign({}, obj);

  parsers.forEach(parser => {
    data[parser.key] = parser.fn(data);
  });

  return data;
}
