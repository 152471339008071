import React from 'react';
import logo from '../logo.svg';

export default function StaticLogo() {
  return (
    <div className="loading display-flex flex-center-center">
      <img className="loading--spinner--logo" src={logo} alt="logo" />
    </div>
  );
}
