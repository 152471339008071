import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function Text({ children, align, uppercase }) {
  const cx = classnames('presentation--text', {
    'text-uppercase': uppercase,
    [`text-${align}`]: align
  });

  return (
    <div className={cx}>
      {children}
    </div>
  );
}

Text.propTypes = {
  uppercase: PropTypes.bool,
  align: PropTypes.oneOf(['center', 'left', 'right'])
};
