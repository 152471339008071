import React from 'react';
import './App.scss';
import {
  HashRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import Presentation from './modules/Presentation/Presentation';
import FoldersList from './modules/FoldersList';
import FolderContentList from './modules/FolderContentList';
import { CommonDataProvider } from './CommonDataContext';
import NotFound from './components/NotFound';
import IconSprite from './icons/Sprite';
import RouteFixer from './modules/RouteFixer';
import StaticLogo from './components/StaticLogo';

function App() {
  return (
    <CommonDataProvider>
      <IconSprite />
      <div className="App">
        <Router>
          <Switch>
            <Route exact path="/_list">
              <FoldersList />
            </Route>
            <Route exact path="/_list/:slug">
              <FolderContentList />
            </Route>
            <Route path="/:folder/:slug/:index">
              <Presentation />
            </Route>
            <Route path="/404">
              <NotFound />
            </Route>
            <Route exact path="/">
              <StaticLogo />
            </Route>
            <Route path="*">
              <RouteFixer />
            </Route>
          </Switch>
        </Router>
      </div>
    </CommonDataProvider>
  );
}

export default App;
