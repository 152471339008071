import React from 'react';
import { withRouter } from 'react-router-dom';
import { getPage } from '../services/Pagedata';
import DataList from './DataList';

function FolderContentList({ match }) {
  const { slug } = match.params;
  const getList = response => response.data.list
    .filter(page => {
      const isNested = page.slug.split('/').length > 1;
      const isNotHidden = page.slug.indexOf('/.') < 0;

      return isNested && isNotHidden;
    })
    .map(page => {
      const text = page.slug.split('/').pop();
      return { text, link: `/${page.slug}/1` };
    });

  return (
    <DataList
      title={slug}
      getData={() => getPage(slug)}
      getList={getList}
    />
  );
}

export default withRouter(FolderContentList);
