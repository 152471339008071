export default function TOCParser({ contentRaw, headings }) {
  let content = contentRaw;
  if (content.includes('<TableOfContents')) {
    const headers = headings.map((heading, index) => {
      if (heading !== '') {
        return `<li key="${heading}-${index}">
        <button onClick={() => document.querySelector('.swiper-container').swiper.slideTo(${index})}>${heading}</button>
        </li>`;
      }
      return null;
    });
    content = content.replace('<TableOfContents/>',
      `<div className='table-of-contents'>
        <h2>Table of Contents</h2>
        <ul>
          ${headers.join('\n')}
        </ul>
      </div> `
    );
  }
  return content;
}
