import React, { useContext } from 'react';
import CommonDataContext from '../../CommonDataContext';

export default function PresentationHeader({ children }) {
  const { header = {} } = useContext(CommonDataContext);

  return (
    <div className="presentation__header__wrapper padding-top-md spacing-md">
      <div className="container">
        <header className="presentation__header display-flex">
          <div className="presentation__header__logo display-flex flex-left-center">
            <svg className="logo" width="33" height="32" xmlns="http://www.w3.org/2000/svg"><path d="M11.781 11.608H4.112c-.74 0-1.341.6-1.341 1.341v5.041h6.188v1.82H.951v-6.86a3.162 3.162 0 0 1 3.161-3.163h7.67V3.21a3.162 3.162 0 0 1 3.16-3.162h18.009v6.55h-1.82v-4.73H14.942c-.74 0-1.341.6-1.341 1.342v6.578H32.95v6.862a3.162 3.162 0 0 1-3.162 3.161H22.22v8.117a3.162 3.162 0 0 1-3.161 3.162H11.78V11.608zm1.82 0v17.66h5.459c.74 0 1.341-.6 1.341-1.34V17.99h9.388c.74 0 1.341-.6 1.341-1.341v-5.041H13.602z" fill="currentColor" /></svg>
            <span>{header.title}</span>
          </div>

          {children}
        </header>
      </div>
    </div>
  );
}
