import React from 'react';
import SlideIcon from './Slide';
import DocumentIcon from './Document';

function IconSprite() {
  return (
    <svg style={{ position: 'absolute' }} width={0} height={0}>
      <SlideIcon/>
      <DocumentIcon/>
    </svg>
  );
}

export default React.memo(IconSprite, () => true);
