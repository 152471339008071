import React from 'react';
import LoadingSpinner from './LoadingSpinner';

export default function Loading() {
  return (
    <div className="loading display-flex flex-center-center">
      <LoadingSpinner />
    </div>
  );
}
