import React from 'react';
import logo from '../logo.svg';

export default function LoadingSpinner() {
  return (
    <div className="loading--spinner">
      <img className="loading--spinner--logo" src={logo} alt="logo" />
      <div className="loading--spinner--spin">Loading...</div>
    </div>
  );
}
